import { Stack, TopNavigation, TopNavigationItems } from "@fidelix/fx-miranda";
import { t } from "@lingui/macro";

import { CompanySelect } from "../company/company-select";
import { useNavigationPath, usePathNavigator } from "../utils/url";
import { NavMenu } from "./nav-menu";

export function Navbar() {
  const { to, isActive } = useNavigationPath();
  const { withSubUrl } = usePathNavigator();

  const items: TopNavigationItems = [
    {
      to: to("devices"),
      label: t`Devices`,
      isActive: isActive("devices"),
    },
    {
      to: to("settings"),
      label: t`Settings`,
      isActive: isActive("settings"),
    },
  ];

  const menuItems = [
    {
      to: withSubUrl("https://analytics2.fidelix.com"),
      label: t`SCADA`,
    },
    {
      to: withSubUrl("https://assets.fidelix.com"),
      label: t`Device Management`,
    },
    {
      to: withSubUrl("https://admin.fidelix.com"),
      label: t`Access Management`,
    },
  ];

  return (
    <TopNavigation variant="primary">
      <TopNavigation.Logo menuItems={menuItems} />
      <TopNavigation.Items items={items} />
      <TopNavigation.Actions>
        <Stack axis="x" spacing="medium" align="center">
          <CompanySelect />
          <NavMenu />
        </Stack>
      </TopNavigation.Actions>
    </TopNavigation>
  );
}
