import { useEffect, useMemo } from "react";
import {
  LoaderFunctionArgs,
  NavigateOptions,
  redirect,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

/**
 * Hook to get typesafe and properly casted params from the URL.
 * You can use this hook instead of `useParams` from React Router when you
 * need to access to url params that you know are defined based on the route
 * context, eg. if you are in a route like `/companies/:companyId` you know
 * that `companyId` will always be defined.
 *
 * @example
 * const { companyId } = useRequiredParams({ companyId: "number" });
 */
export function useRequiredParams<
  T extends Record<string, "string" | "number">,
>(options: T) {
  const params = useParams();

  // Validate and cast params based on the provided paramTypes
  const castedParams = Object.entries(options).reduce((acc, [key, type]) => {
    const paramValue = params[key];

    // Check if the param exists
    if (paramValue === undefined || paramValue === null) {
      throw new Error(`Missing required parameter: ${key}`);
    }

    // Attempt to cast the param to the specified type
    acc[key] = type === "number" ? Number(paramValue) : paramValue;

    return acc;
  }, {});

  return castedParams as {
    [K in keyof T]: T[K] extends "number" ? number : string;
  };
}

export function useNavigationPath() {
  const location = useLocation();
  const params = useRequiredParams({ companyId: "number" });
  const to = (path: string) => `/company/${params.companyId}/${path}`;
  const isActive = (path: string) => location.pathname.includes(to(path));
  return { to, isActive };
}

const parseBaseUrl = (url: string): string | null => {
  const match = url.match(/^(.*?)-/);
  return match ? match[0] : null;
};

export function usePathNavigator() {
  const baseUrl = parseBaseUrl(window.location.host);
  const withSubUrl = (path: string): string => {
    if (!baseUrl) {
      return path;
    }
    return constructNewUrl(path, baseUrl);
  };
  return { withSubUrl };
}

function constructNewUrl(path: string, baseUrl: string | null) {
  const url = new URL(path);
  const [hostnameParts, ...rest] = url.hostname.split(".");
  const updatedHost = `${baseUrl}${hostnameParts}`;
  url.hostname = [updatedHost, ...rest].join(".");
  return url.toString();
}

/**
 * A utility hook to get and update URL search params and keep them in sync with
 * session storage so that they are persisted across page navigations.
 *
 * @example
 *
 * ```tsx
 * const {
 *   searchParams,
 *   setSearchParams
 * } = usePersistedSearchParams("level-tree");
 *
 * const openLevels = searchParams.getAll("open");
 *
 * function handleLevelSelect(levelId: number) {
 *   setSearchParams((params) => {
 *     params.set("level", String(levelId));
 *     params.set("page", "1");
 *     params.delete("device");
 *   });
 * }
 * ```
 */
export function usePersistedSearchParams(key: string) {
  const { companyId } = useRequiredParams({ companyId: "string" });
  const persistKey = `persisted-search-params:${companyId}-${key}`;
  const persistedParams = sessionStorage.getItem(persistKey);

  /**
   * Restore persisted search params from sessionStorage if they exist and there
   * are no existing search params in the URL.
   * The URL params always have precedence over persisted params so that
   * it is possible to come to the page via a link that has params or to override
   * the persisted params by manually modifying them in the URL.
   *
   * NOTE: we don't want to use `useEffect` here because it would cause the
   * component to render with incorrect params on first render.
   */
  let initialParams: URLSearchParams | undefined;

  if (!window.location.search && persistedParams) {
    initialParams = new URLSearchParams(persistedParams);
    // Update the URL to match the persisted params
    window.location.search = initialParams.toString();
  }

  const [params, setParams] = useSearchParams(initialParams);

  function setSearchParams(handler: (prev: URLSearchParams) => void) {
    setParams((params) => {
      handler(params);
      sessionStorage.setItem(persistKey, params.toString());
      return params;
    });
  }

  function removeSearchParam(key: string) {
    setSearchParams((prev) => {
      prev.delete(key);
      sessionStorage.setItem(persistKey, prev.toString());
      return prev;
    });
  }

  function clearSearchParams() {
    setParams("");
    sessionStorage.removeItem(persistKey);
  }

  // Persist initial search params to session storage
  useEffect(() => {
    sessionStorage.setItem(persistKey, params.toString());
  }, [persistKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    searchParams: params,
    setSearchParams,
    removeSearchParam,
    clearSearchParams,
  };
}

/**
 * Get the key to store persisted search params in session storage.
 *
 * NOTE: the number in the key is the version of the persisted search params.
 * It allows us to invalidate old persisted search params when the structure
 * of the persisted params data changes.
 */
const getPersistKey = (key: string) => `search-params:v1:${key}`;

/**
 * A loader function for React Router routes to restore persisted search params
 * from session storage for given route. This ensures that the search params
 * are restored before the route component is rendered so if any of the components
 * within the route access the search params they will be up to date.
 */
export function restoreSearchParamsLoader({
  request,
  defaultParams,
}: LoaderFunctionArgs & { defaultParams?: string }) {
  const url = new URL(request.url);
  const isPageNavigation = url.pathname !== window.location.pathname;

  /**
   * Only restore search params if there are no search params in the URL and
   * we are navigating to a new page instead of eg. updating the search params
   * in the current page (which causes a navigation -> which triggers this loader).
   *
   * The search params in the URL have higher priority than the persisted ones.
   */
  if (isPageNavigation && url.searchParams.size === 0) {
    const persistKey = getPersistKey(url.pathname);
    const persistedParams = sessionStorage.getItem(persistKey) ?? defaultParams;

    /**
     * NOTE: `URLSearchParams` doesn't provide a way to check if the string
     * is a valid query string so we do some basic validation here.
     */
    if (persistedParams && persistedParams.includes("=")) {
      url.search = persistedParams;
      return redirect(`${url.pathname}${url.search}`);
    }
  }

  // React Router loaders need to return some value
  return null;
}

type ParseConfig = Record<
  string,
  | { type: "string"; defaultValue?: string }
  | { type: "number"; defaultValue?: number }
  | { parse: (value: URLSearchParams) => unknown }
>;

/**
 * A utility hook to parse and type URL search params based on a configuration
 * object. This hook is useful when you want to access URL search params in a
 * typesafe way and with proper parsing and type casting.
 *
 * @example
 * ```tsx
 * const { parsedParams } = useParsedSearchParams({
 *   page: { type: "number", defaultValue: 1 },
 *   search: { type: "string", defaultValue: "" },
 *   order: { type: "string", defaultValue: "asc" },
 *   sort: { type: "string" }, // You can omit default value
 *   selected: { parse: (p) => new Set(p.getAll("selected").map(Number)) },
 * });
 * ```
 */
export function useParsedSearchParams<T extends ParseConfig>(config: T) {
  const [searchParams, setParams] = useSearchParams();

  function setSearchParams(
    handler: (prev: URLSearchParams) => void,
    navigateOpts?: NavigateOptions,
  ) {
    setParams((params) => {
      handler(params);
      return params;
    }, navigateOpts);
  }

  function removeSearchParam(key: string, value?: string) {
    setSearchParams((prev) => {
      prev.delete(key, value);
      return prev;
    });
  }

  function clearSearchParams() {
    setParams("");
  }

  const parsedParams = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsed: Record<string, any> = {};

    for (const [key, options] of Object.entries(config)) {
      if ("parse" in options) {
        parsed[key] = options.parse(searchParams);
        continue;
      }

      const value = searchParams.get(key);
      const { type, defaultValue } = options;

      if (value !== null) {
        if (type === "number") {
          const numValue = Number(value);
          parsed[key] = isNaN(numValue) ? defaultValue : numValue;
        } else {
          parsed[key] = value;
        }
      } else {
        parsed[key] = defaultValue;
      }
    }

    /**
     * Typing this without casting is impossible...
     *
     * You can basically read the following `extends` clauses as if they were
     * if-else statements:
     *
     * A extends B ? C : D
     *
     * is equivalent to:
     *
     * if (A has the shape of B)
     *  then use type C
     *  else use type D
     */
    const parsedParams = parsed as {
      // 1. Handle custom `parse` fn based configs
      [K in keyof T]: T[K] extends {
        parse: (value: URLSearchParams) => infer P;
      }
        ? P
        : // 3. Handle `string` and `number` based configs
          T[K] extends {
              type: infer TType extends "number" | "string";
              defaultValue?: infer TDefault;
            }
          ? // 3b. Handle the case where the `defaultValue` is `undefined`
            undefined extends TDefault
            ? TType extends "number"
              ? number | undefined
              : string | undefined
            : // 4. Get the type based on the `defaultValue` type
              TDefault
          : never; // 5. Dissallow all other types
    };

    return parsedParams;

    // The `config` object is not expected to change during the component lifecycle
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    rawParams: searchParams,
    parsedParams,
    setSearchParams,
    removeSearchParam,
    clearSearchParams,
  };
}
